import { inject, Injectable } from '@angular/core';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import {
  PortalClient,
  PortalUser,
  PortalUserClientUpdatePayload,
  PortalUserUpdatePayload
} from '@pinnakl/portal-v2/domain';
import { getErrorMessage } from '@pinnakl/shared/util-helpers';
import { PinnaklUIToastMessage } from '@pinnakl/shared/util-providers';
import { firstValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PortalUserApiService {
  private readonly portalUserEndpoint = 'portal/users';
  private readonly wsp = inject(WebServiceProvider);
  private readonly toast = inject(PinnaklUIToastMessage);

  async loadPortalUser(): Promise<PortalUser> {
    try {
      return await firstValueFrom(this.getUser());
    } catch (error) {
      this.toast.warning('Unable to fetch portal user information. ' + getErrorMessage('', error));
      throw error;
    }
  }

  async updatePortalUser(user: PortalUserUpdatePayload): Promise<PortalUser> {
    try {
      return await firstValueFrom(this.updateUser(user));
    } catch (error) {
      this.toast.warning('Unable to update portal user information. ' + getErrorMessage('', error));
      throw error;
    }
  }

  async updatePortalUserClient(clientDetails: PortalUserClientUpdatePayload): Promise<void> {
    try {
      return await this.wsp.putHttp({
        endpoint: `${this.portalUserEndpoint}/client-config`,
        body: clientDetails
      });
    } catch (error) {
      this.toast.warning(
        'Unable to update portal user client information. ' + getErrorMessage('', error)
      );
      throw error;
    }
  }

  async loadPortalClient(): Promise<PortalClient> {
    try {
      return await this.wsp.getHttp({
        endpoint: `${this.portalUserEndpoint}/selected-client`
      });
    } catch (error) {
      this.toast.warning(
        'Unable to fetch portal client information. ' + getErrorMessage('', error)
      );
      throw error;
    }
  }

  private getUser(): Observable<PortalUser> {
    return this.wsp.get<PortalUser>({
      endpoint: this.portalUserEndpoint
    });
  }

  private updateUser(user: PortalUserUpdatePayload): Observable<PortalUser> {
    return this.wsp.put({ endpoint: this.portalUserEndpoint, body: user });
  }
}
