import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  InputContainerComponent,
  LabelContentComponent,
  PrimeInputPasswordComponent
} from '@pinnakl/shared/ui/prime';
import { InlineSVGModule } from '@pinnakl/shared/util-providers';

export enum SetPortalUserPasswordErrorNames {
  LowerCase = 'lowercase',
  UpperCase = 'uppercase',
  Digit = 'digit',
  Symbol = 'symbol',
  Length = 'length',
  Match = 'match'
}

@Component({
  standalone: true,
  selector: 'portal-password-details',
  templateUrl: './password-details.component.html',
  styleUrls: ['./password-details.component.scss'],
  imports: [
    InlineSVGModule,
    NgClass,
    NgTemplateOutlet,
    InputContainerComponent,
    LabelContentComponent,
    ReactiveFormsModule,
    PrimeInputPasswordComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordDetailsComponent {
  protected readonly passwordRules = [
    {
      rule: 'oneLowerCase',
      label: 'One lowercase character',
      error: SetPortalUserPasswordErrorNames.LowerCase
    },
    {
      rule: 'oneUpperCase',
      label: 'One uppercase character',
      error: SetPortalUserPasswordErrorNames.UpperCase
    },
    {
      rule: 'oneNumber',
      label: 'One number',
      error: SetPortalUserPasswordErrorNames.Digit
    },
    {
      rule: 'oneSpecialCharacter',
      label: 'One special character',
      error: SetPortalUserPasswordErrorNames.Symbol
    },
    {
      rule: 'minLength',
      label: 'Minimum 8 characters',
      error: SetPortalUserPasswordErrorNames.Length
    },
    {
      rule: 'match',
      label: 'Both passwords match',
      error: SetPortalUserPasswordErrorNames.Match
    }
  ];
  form = input.required<
    FormGroup<{
      newPassword: FormControl<string>;
      confirmPassword: FormControl<string>;
    }>
  >();

  isRuleFollowed(rule: SetPortalUserPasswordErrorNames): boolean {
    const formErrors = this.form().errors;
    return formErrors == null ? true : !formErrors[rule];
  }
}
