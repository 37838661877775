<div class="my-4 w-full flex align-items-center justify-content-center">
  @if (dialogConfig.data.logoUrl) {
    <img
      [src]="dialogConfig.data.logoUrl"
      class="h-7_50rem"
    />
  } @else {
    <i
      class="w-7_50rem h-7_50rem flex"
      inlineSVG="sprites.svg#blankstate"
    ></i>
  }
</div>
<div class="w-full px-4 pb-4">
  {{ dialogConfig.data.disclaimer }}
</div>
<div class="pt-3 pb-4 px-4 flex align-items-center justify-content-between">
  @if (dialogConfig.data.showBackButton) {
    <prime-button
      label="Back"
      (onClick)="back()"
      severity="secondary"
      [text]="true"
    ></prime-button>
  }
  <prime-button
    (onClick)="acceptAndContinue()"
    [ngClass]="{
      'ml-auto': !dialogConfig.data.showBackButton
    }"
    label="Accept & Continue"
    severity="primary"
  ></prime-button>
</div>
