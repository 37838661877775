<div>
  <div class="flex align-items-center flex-column w-full mb-2_50rem">
    <div
      class="h-4rem w-4rem border-circle bg-blue-200 flex align-items-center justify-content-center"
    >
      <i
        class="flex w-1_25rem h-1_25rem"
        inlineSVG="sprites.svg#profile"
      ></i>
    </div>
    <div class="mt-0_75rem text-xl line-height-1_75rem font-semibold">
      {{
        step() === 'profile-details'
          ? dialogConfig.data.user.email
          : profileDetails.value.firstName + ' ' + profileDetails.value.lastName
      }}
    </div>
    @if (step() === 'password-details') {
      <div class="text-color-secondary mt-1 line-height-1rem">
        {{ dialogConfig.data.user.email }}
      </div>
    }
  </div>
  @if (step() === 'profile-details') {
    <portal-profile-details [form]="profileDetails"></portal-profile-details>
  } @else {
    <portal-password-details [form]="passwordDetails"></portal-password-details>
  }
</div>
<div
  [ngClass]="step() === 'password-details' ? 'justify-content-between' : 'justify-content-end'"
  class="flex align-items-center px-4 pb-4 mt-5 pt-2"
>
  @if (step() === 'password-details') {
    <prime-button
      label="Back"
      (onClick)="changeStep('profile-details')"
      severity="secondary"
      [text]="true"
    ></prime-button>
  }
  <prime-button
    (onClick)="step() === 'password-details' ? acceptAndContinue() : changeStep('password-details')"
    [disabled]="step() === 'password-details' ? passwordDetails.invalid : profileDetails.invalid"
    label="{{ step() === 'password-details' ? 'Save Profile' : 'Next' }}"
  ></prime-button>
</div>
