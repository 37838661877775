@if (isLoading()) {
  <pinnakl-local-spinner-simple-mode-container
    containerHeight="100vh"
    containerWidth="100vw"
    size="150px"
  ></pinnakl-local-spinner-simple-mode-container>
} @else {
  @if (user()) {
    <router-outlet></router-outlet>
  }
}
<p-toast></p-toast>
