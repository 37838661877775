import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  inject,
  input,
  signal,
  untracked
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  InputContainerComponent,
  InputValidationComponent,
  LabelContentComponent,
  PrimeInputMaskComponent,
  PrimeInputTextComponent
} from '@pinnakl/shared/ui/prime';
import { InlineSVGModule } from '@pinnakl/shared/util-providers';
import { SharedFilterModule } from '@progress/kendo-angular-grid';

@Component({
  standalone: true,
  selector: 'portal-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.scss'],
  imports: [
    InputContainerComponent,
    InputValidationComponent,
    LabelContentComponent,
    PrimeInputTextComponent,
    ReactiveFormsModule,
    SharedFilterModule,
    PrimeInputMaskComponent,
    InlineSVGModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileDetailsComponent {
  private readonly destroyRef = inject(DestroyRef);
  protected readonly formValues = signal<{
    firstName: string;
    lastName: string;
    phone: string;
    otp: 'email' | 'phone';
  } | null>(null);
  protected readonly isMobileSelected = computed(() => {
    const formValue = this.formValues();
    return formValue?.otp === 'phone';
  });
  protected readonly isEmailSelected = computed(() => {
    const formValue = this.formValues();
    return formValue?.otp === 'email';
  });
  form = input.required<FormGroup>();

  constructor() {
    effect(() => {
      const form = this.form();
      untracked(() => this.formValues.set(form.getRawValue()));
      form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(values => {
        this.formValues.set(values);
      });
    });
  }

  changeOtpMethod(newMethod: 'email' | 'phone'): void {
    this.form().get('otp')?.patchValue(newMethod);
  }
}
