<ng-container [formGroup]="form()">
  <div class="w-full px-4 flex align-items-center gap-3">
    <prime-input-container class="flex-grow-1">
      <ng-template #labelContent>
        <prime-label-content
          formControlName="firstName"
          label="First Name"
        ></prime-label-content>
      </ng-template>
      <ng-template #inputContent>
        <prime-text-input formControlName="firstName"></prime-text-input>
      </ng-template>
      <ng-template #validationContent>
        <prime-input-validation
          class="mt-1"
          formControlName="firstName"
        ></prime-input-validation>
      </ng-template>
    </prime-input-container>
    <prime-input-container class="flex-grow-1">
      <ng-template #labelContent>
        <prime-label-content
          formControlName="lastName"
          label="Last Name"
        ></prime-label-content>
      </ng-template>
      <ng-template #inputContent>
        <prime-text-input formControlName="lastName"></prime-text-input>
      </ng-template>
      <ng-template #validationContent>
        <prime-input-validation
          class="mt-1"
          formControlName="lastName"
        ></prime-input-validation>
      </ng-template>
    </prime-input-container>
  </div>
  <div class="w-full px-4 mt-1">
    <prime-input-container class="flex-grow-1">
      <ng-template #labelContent>
        <prime-label-content
          formControlName="phone"
          label="Phone Number"
        ></prime-label-content>
      </ng-template>
      <ng-template #inputContent>
        <prime-mask-input
          formControlName="phone"
          mask="+9-999-999-9999"
          placeholder="e.g. +1-234-567-8901"
          styleClass="w-100"
        >
        </prime-mask-input>
      </ng-template>
      <ng-template #validationContent>
        <prime-input-validation
          class="mt-1"
          formControlName="phone"
        ></prime-input-validation>
      </ng-template>
    </prime-input-container>
  </div>
  <div class="w-full px-4 mt-1">
    <prime-label-content
      formControlName="otp"
      label="One Time Password"
    ></prime-label-content>
    <div class="mt-3 w-full flex align-items-center gap-3">
      <div
        (click)="changeOtpMethod('email')"
        [ngClass]="{
          'border-bluegray-300': isMobileSelected(),
          'border-blue-600': isEmailSelected()
        }"
        class="p-3 border-round-xl border-1 cursor-pointer"
      >
        <div class="flex align-items-start justify-content-between">
          <div
            [ngClass]="{
              'border-bluegray-300': isMobileSelected(),
              'border-blue-600': isEmailSelected()
            }"
            class="w-2_50rem h-2_50rem border-1 border-round-xl flex align-items-center justify-content-center"
          >
            <i
              [ngClass]="{
                'text-blue-600': isEmailSelected()
              }"
              class="flex w-1rem h-1rem"
              inlineSVG="sprites.svg#mail"
            ></i>
          </div>
          @if (isEmailSelected()) {
            <i
              class="flex w-1rem h-1rem text-blue-600"
              inlineSVG="sprites.svg#check-circle"
            ></i>
          }
        </div>
        <div class="mt-4 text-base font-semibold line-height-1_50rem">Email</div>
        <div class="text-color-secondary line-height-1_25rem">
          Receive an email message containing a verification code
        </div>
      </div>
      <div
        (click)="changeOtpMethod('phone')"
        [ngClass]="{
          'border-bluegray-300': isEmailSelected(),
          'border-blue-600': isMobileSelected()
        }"
        class="p-3 border-round-xl border-1 cursor-pointer"
      >
        <div class="flex align-items-start justify-content-between">
          <div
            [ngClass]="{
              'border-bluegray-300': isEmailSelected(),
              'border-blue-600': isMobileSelected()
            }"
            class="w-2_50rem h-2_50rem border-1 border-round-xl flex align-items-center justify-content-center"
          >
            <i
              [ngClass]="{
                'text-blue-600': isMobileSelected()
              }"
              class="flex w-1rem h-1rem"
              inlineSVG="sprites.svg#icon-mobile"
            ></i>
          </div>
          @if (isMobileSelected()) {
            <i
              class="flex w-1rem h-1rem text-blue-600"
              inlineSVG="sprites.svg#check-circle"
            ></i>
          }
        </div>
        <div class="mt-4 text-base font-semibold line-height-1_50rem">Mobile</div>
        <div class="text-color-secondary line-height-1_25rem">
          Receive a phone message with a verification code
        </div>
      </div>
    </div>
  </div>
</ng-container>
