import { Injectable } from '@angular/core';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import { VdrUser, VdrUserWithClientConfig } from '@pinnakl/vdr/domain';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VdrUsersApiService {
  private readonly _crmVdrUsersWithClientConfigEndpoint =
    'v3/entities/crm_vdr_users_with_clientconfig';
  private readonly _crmVdrUsersEndpoint = 'portal/users';

  constructor(private readonly wsp: WebServiceProvider) {}

  getVdrUsers(): Promise<any[]> {
    return this.wsp.getHttp<VdrUserWithClientConfig[]>({
      endpoint: this._crmVdrUsersWithClientConfigEndpoint
    });
  }

  // PORTAL
  getVdrPortalUserById(id?: number): Observable<VdrUser> {
    return this.wsp.get<VdrUser>({
      endpoint: id ? `${this._crmVdrUsersEndpoint}/${id}` : this._crmVdrUsersEndpoint
    });
  }

  putVdrPortalUser(body: VdrUser): Observable<VdrUser> {
    return this.wsp.put({
      endpoint: this._crmVdrUsersEndpoint,
      body
    });
  }

  updateCurrentVdrPortalUser(data: Partial<VdrUser>): Observable<VdrUser> {
    return this.getVdrPortalUserById().pipe(
      switchMap(vdrUser =>
        this.putVdrPortalUser({
          firstName: data.firstName ?? vdrUser.firstName,
          lastName: data.lastName ?? vdrUser.lastName,
          phoneNumber: data.phoneNumber ?? vdrUser.phoneNumber,
          authType: 'TwoFactor',
          otpChannel: data.otpChannel ?? vdrUser.otpChannel,
          password: data.password ?? vdrUser.password
        } as VdrUser)
      )
    );
  }
}
