import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { PrimeButtonComponent } from '@pinnakl/shared/ui/prime';
import { InlineSVGModule } from '@pinnakl/shared/util-providers';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

export interface DisclaimerModalData {
  logoUrl?: string;
  disclaimer: string;
  showBackButton: boolean;
}

@Component({
  standalone: true,
  selector: 'portal-disclaimer-modal',
  templateUrl: './disclaimer-modal.component.html',
  styleUrls: ['./disclaimer-modal.component.scss'],
  imports: [PrimeButtonComponent, NgClass, InlineSVGModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisclaimerModalComponent {
  private readonly dialogRef = inject(DynamicDialogRef);
  protected readonly dialogConfig: DynamicDialogConfig<DisclaimerModalData> =
    inject(DynamicDialogConfig);

  back(): void {
    this.dialogRef.close(false);
  }

  acceptAndContinue(): void {
    this.dialogRef.close(true);
  }
}
