import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit, signal } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { PortalClient, PortalUser } from '@pinnakl/portal-v2/domain';
import { PrimeButtonComponent } from '@pinnakl/shared/ui/prime';
import { InlineSVGModule } from '@pinnakl/shared/util-providers';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  PasswordDetailsComponent,
  SetPortalUserPasswordErrorNames
} from './password-details/password-details.component';
import { ProfileDetailsComponent } from './profile-details/profile-details.component';

const MIN_PASSWORD_LENGTH = 8;

export interface ProfileRegistrationModalDataResult {
  firstName: string;
  lastName: string;
  phone: string;
  otp: 'email' | 'phone';
  newPassword: string;
}

@Component({
  standalone: true,
  selector: 'portal-profile-registration-modal',
  templateUrl: './profile-registration-modal.component.html',
  styleUrls: ['./profile-registration-modal.component.scss'],
  imports: [
    ProfileDetailsComponent,
    PasswordDetailsComponent,
    PrimeButtonComponent,
    InlineSVGModule,
    NgClass
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileRegistrationModalComponent implements OnInit {
  private readonly dialogRef = inject(DynamicDialogRef);
  protected readonly dialogConfig: DynamicDialogConfig<{
    profileDetails?: ProfileRegistrationModalDataResult;
    client: PortalClient;
    user: PortalUser;
  }> = inject(DynamicDialogConfig);
  protected readonly step = signal<'profile-details' | 'password-details'>('profile-details');
  protected readonly profileDetails = new FormGroup({
    firstName: new FormControl('', { validators: Validators.required, nonNullable: true }),
    lastName: new FormControl('', { validators: Validators.required, nonNullable: true }),
    phone: new FormControl('', { nonNullable: true }),
    otp: new FormControl<'email' | 'phone'>('email', { nonNullable: true })
  });
  protected readonly passwordDetails = new FormGroup(
    {
      newPassword: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true
      }),
      confirmPassword: new FormControl('', { validators: Validators.required, nonNullable: true })
    },
    {
      validators: [
        (group: AbstractControl): null | { [SetPortalUserPasswordErrorNames.LowerCase]: true } => {
          const control = group.get('newPassword');
          return control?.value && /[a-z]/.test(control.value)
            ? null
            : { [SetPortalUserPasswordErrorNames.LowerCase]: true };
        },
        (group: AbstractControl): null | { [SetPortalUserPasswordErrorNames.UpperCase]: true } => {
          const control = group.get('newPassword');
          return control?.value && /[A-Z]/.test(control.value)
            ? null
            : { [SetPortalUserPasswordErrorNames.UpperCase]: true };
        },
        (group: AbstractControl): null | { [SetPortalUserPasswordErrorNames.Digit]: true } => {
          const control = group.get('newPassword');
          return control?.value && /[0-9]/.test(control.value)
            ? null
            : { [SetPortalUserPasswordErrorNames.Digit]: true };
        },
        (group: AbstractControl): null | { [SetPortalUserPasswordErrorNames.Symbol]: true } => {
          const control = group.get('newPassword');
          return control?.value && /[!@#$%^&*]/.test(control.value)
            ? null
            : { [SetPortalUserPasswordErrorNames.Symbol]: true };
        },
        (group: AbstractControl): null | { [SetPortalUserPasswordErrorNames.Length]: true } => {
          const control = group.get('newPassword');
          return control?.value && control.value.trim().length >= MIN_PASSWORD_LENGTH
            ? null
            : { [SetPortalUserPasswordErrorNames.Length]: true };
        },
        (group: AbstractControl): null | { [SetPortalUserPasswordErrorNames.Match]: true } => {
          const control = group.get('newPassword');
          return control?.value &&
            control?.value.trim().length >= MIN_PASSWORD_LENGTH &&
            control.value === group?.get('confirmPassword')?.value
            ? null
            : { [SetPortalUserPasswordErrorNames.Match]: true };
        }
      ]
    }
  );

  ngOnInit(): void {
    if (this.dialogConfig.data?.profileDetails) {
      this.step.set('password-details');
      this.profileDetails.patchValue({
        firstName: this.dialogConfig.data.profileDetails.firstName,
        lastName: this.dialogConfig.data.profileDetails.lastName,
        phone: this.dialogConfig.data.profileDetails.phone,
        otp: this.dialogConfig.data.profileDetails.otp
      });
      this.passwordDetails.patchValue({
        newPassword: this.dialogConfig.data.profileDetails.newPassword,
        confirmPassword: this.dialogConfig.data.profileDetails.newPassword
      });
    } else {
      this.profileDetails.patchValue({
        firstName: this.dialogConfig.data?.user.firstName,
        lastName: this.dialogConfig.data?.user.lastName,
        phone: this.dialogConfig.data?.user.phoneNumber ?? '',
        otp: this.dialogConfig.data?.user.otpChannel.toLowerCase() === 'email' ? 'email' : 'phone'
      });
    }
  }

  changeStep(step: 'profile-details' | 'password-details'): void {
    this.step.set(step);
  }

  acceptAndContinue(): void {
    this.dialogRef.close({
      ...this.profileDetails.value,
      ...this.passwordDetails.value
    });
  }
}
