import { JsonPipe, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnInit,
  computed,
  inject,
  signal
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { AuthService } from '@pinnakl/auth/providers';
import { CurrentUserStore } from '@pinnakl/core/data-providers';
import { PortalUserStore } from '@pinnakl/portal-v2/data-access';
import {
  FirstLetterAvatarComponent,
  LocalSpinnerSimpleModeContainerComponent
} from '@pinnakl/shared/base-components';
import {
  PrimeButtonComponent,
  PrimeSingleDropdownComponent,
  RouterToggleComponent
} from '@pinnakl/shared/ui/prime';
import { InlineSVGModule } from '@pinnakl/shared/util-providers';
import { MenuItem } from 'primeng/api';
import { DividerModule } from 'primeng/divider';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { Ripple } from 'primeng/ripple';
import { SkeletonModule } from 'primeng/skeleton';

@Component({
  selector: 'investor-portal-header',
  standalone: true,
  templateUrl: './investor-portal-header.component.html',
  styleUrls: ['./investor-portal-header.component.scss'],
  imports: [
    RouterToggleComponent,
    InlineSVGModule,
    FirstLetterAvatarComponent,
    PrimeSingleDropdownComponent,
    ReactiveFormsModule,
    JsonPipe,
    OverlayPanelModule,
    RouterLink,
    LocalSpinnerSimpleModeContainerComponent,
    DividerModule,
    PrimeButtonComponent,
    Ripple,
    RouterLinkActive,
    SkeletonModule,
    NgClass
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvestorPortalHeaderComponent implements OnInit {
  private readonly authService = inject(AuthService);
  private readonly portalUserStore = inject(PortalUserStore);
  protected readonly selectedClient = this.portalUserStore.selectedClient;
  protected readonly allowClientChange = computed(() => {
    const user = this.portalUserStore.portalUser();
    return !!user?.hasMultipleClients;
  });
  readonly logoutProcessing = signal(false);
  readonly user = inject(CurrentUserStore).currentUser();
  readonly tabs: MenuItem[] = [
    {
      label: 'Documents',
      routerLink: 'dataroom',
      routerLinkActiveOptions: {
        paths: 'subset',
        queryParams: 'ignored',
        matrixParams: 'ignored',
        fragment: 'ignored'
      }
    }
  ];
  appVersion = localStorage.getItem('appVersion');

  @HostBinding('class') componentClasses = 'block w-full h-6rem';

  ngOnInit(): void {
    const client = this.portalUserStore.selectedClient();
    if (client?.canAccessFundPerformance) {
      this.tabs.push({
        label: 'Fund Performance',
        routerLink: '/fund-performance',
        routerLinkActiveOptions: { exact: true }
      });
    }
  }

  changeClient(): void {
    this.authService.selectClientInAuthApp();
  }

  logout(): void {
    this.logoutProcessing.set(true);
    this.authService.logout();
  }
}
