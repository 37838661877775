import { Component, inject, OnInit, signal } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthService } from '@pinnakl/auth/providers';
import { AuthPopupService } from '@pinnakl/authentication';
import { CurrentUserStore } from '@pinnakl/core/data-providers';
import {
  LocalSpinnerSimpleModeContainerComponent,
  PinnaklGlobalSpinnerComponent
} from '@pinnakl/shared/base-components';
import { PinnaklSpinnerService } from '@pinnakl/shared/util-providers';
import { ToastModule } from 'primeng/toast';
import { distinctUntilChanged, filter, take, tap } from 'rxjs/operators';

/*
 * After removing old settings component we need to remove shared-ui from project.json
 * */

@Component({
  standalone: true,
  imports: [
    RouterModule,
    LocalSpinnerSimpleModeContainerComponent,
    ToastModule,
    PinnaklGlobalSpinnerComponent
  ],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [AuthPopupService, PinnaklSpinnerService]
})
export class AppComponent implements OnInit {
  private readonly authService = inject(AuthService);
  private readonly currentUserStore = inject(CurrentUserStore);
  protected readonly user = this.currentUserStore.currentUserInfo;
  protected readonly isLoading = signal(true);

  constructor() {
    this.currentUserStore.removeUser();
    this.authService.init();
  }

  ngOnInit(): void {
    this.authService.isAuthenticated$
      .pipe(
        distinctUntilChanged(),
        filter(v => v),
        take(1),
        tap(() => this.isLoading.set(false))
      )
      .subscribe();
  }
}
