@if (loadingPortalUser() || !loadedPortalUser()) {
  <pinnakl-local-spinner-simple-mode-container
    containerHeight="100%"
    size="100px"
  ></pinnakl-local-spinner-simple-mode-container>
} @else {
  <investor-portal-header></investor-portal-header>

  <div class="investor-portal-content">
    @if (loadedPortalUser()) {
      @if (selectedClient()) {
        @if (showRoutes()) {
          <router-outlet></router-outlet>
        }
      } @else {
        <div class="w-full mt-6 text-lg">No client to select</div>
        <div class="w-full mt-3 mb-6 text-lg">Connect Pinnakl support</div>
      }
    } @else {
      <div class="w-full my-6 text-lg">Something went wrong. Please contact Pinnakl support</div>
    }
  </div>
}
