import { inject } from '@angular/core';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import {
  PortalClient,
  PortalUser,
  PortalUserClientUpdatePayload,
  PortalUserUpdatePayload
} from '@pinnakl/portal-v2/domain';
import { withDevtools } from '@pinnakl/shared/signal-store';
import { PortalUserApiService } from './portal-user-api.service';

interface PortalUserStoreState {
  portalUserLoading: boolean;
  portalUserLoaded: boolean;
  portalUserProcessing: boolean;
  portalUser: PortalUser | null;
  selectedClient: PortalClient | null;
}

export const PortalUserStore = signalStore(
  { providedIn: 'root' },
  withDevtools('portalData'),
  withState<PortalUserStoreState>({
    portalUserLoading: false,
    portalUserLoaded: false,
    portalUser: null,
    portalUserProcessing: false,
    selectedClient: null
  }),
  withMethods((store, apiService = inject(PortalUserApiService)) => ({
    loadPortalUser: async (forceRefetch = false): Promise<PortalUser> => {
      const portalUserFromStore = store.portalUser();
      if (portalUserFromStore && !forceRefetch) {
        return Promise.resolve(portalUserFromStore);
      }
      try {
        patchState(store, { portalUserLoading: true });
        const portalUser = await apiService.loadPortalUser();
        const selectedClient = await apiService.loadPortalClient();
        patchState(store, {
          portalUserLoaded: true,
          portalUserLoading: false,
          portalUser,
          selectedClient
        });

        return portalUser;
      } catch (error) {
        patchState(store, {
          portalUserLoaded: false,
          portalUserLoading: false,
          portalUser: null
        });
        throw error;
      }
    },
    updatePortalUser: async (user: PortalUserUpdatePayload): Promise<PortalUser> => {
      patchState(store, { portalUserProcessing: true });
      const portalUser = await apiService.updatePortalUser(user);

      patchState(store, {
        portalUser,
        portalUserProcessing: false
      });
      return portalUser;
    },
    updatePortalUserClient: async (clientDetails: PortalUserClientUpdatePayload): Promise<void> => {
      return await apiService.updatePortalUserClient(clientDetails);
    }
  }))
);
