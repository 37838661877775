import { computed, inject } from '@angular/core';
import { patchState, signalStore, type, withComputed, withMethods, withState } from '@ngrx/signals';
import { withEntities } from '@ngrx/signals/entities';
import { withCallState, withDataService, withDevtools } from '@pinnakl/shared/signal-store';
import { OtpChannelType, User, UserAuthType, UserRoles } from '@pinnakl/shared/types';
import { UsersApiService } from './users-api.service';

export interface MultipleUsersUpdateItem {
  userId: number;
  authType: UserAuthType;
  otpChannel: OtpChannelType;
  tokenReAuthInterval: number;
}

const usersStoreFeatureName = 'users';

export const UsersStore = signalStore(
  { providedIn: 'root' },
  withDevtools(usersStoreFeatureName),
  withCallState({
    collection: usersStoreFeatureName
  }),
  withEntities({
    entity: type<User>(),
    collection: usersStoreFeatureName
  }),
  withComputed(store => ({
    activeUsers: computed(() => {
      return store.usersEntities().filter(user => user.active);
    })
  })),
  withDataService<User, Record<string, any>, UsersApiService, 'users'>({
    dataServiceType: UsersApiService,
    filter: {},
    collection: usersStoreFeatureName
  }),
  withState({
    updateUserProcessing: false,
    multipleUsersUpdateProcessing: false
  }),
  withMethods((store, apiService = inject(UsersApiService)) => ({
    changeRole: async (
      userId: number,
      role: UserRoles,
      change: 'add' | 'remove'
    ): Promise<void> => {
      return await apiService.changeRole(userId, role, change);
    },
    updateMultipleUsers: async (users: MultipleUsersUpdateItem[]): Promise<void> => {
      patchState(store, { multipleUsersUpdateProcessing: true });
      try {
        return await apiService.updateMultipleUsers(users);
      } finally {
        patchState(store, { multipleUsersUpdateProcessing: false });
      }
    }
  }))
);
