<div class="w-full px-4">
  <div class="flex align-items-center no-gutters">
    <div class="flex flex-column gap-2 pr-1 col-6">
      @for (p of passwordRules.slice(0, 3); track p.rule) {
        <ng-container *ngTemplateOutlet="passwordRule; context: { $implicit: p }"></ng-container>
      }
    </div>
    <div class="flex flex-column gap-2 col-6">
      @for (p of passwordRules.slice(3); track p.rule) {
        <ng-container *ngTemplateOutlet="passwordRule; context: { $implicit: p }"></ng-container>
      }
    </div>
  </div>
  <prime-input-container labelClasses="mt-4">
    <ng-template #labelContent>
      <prime-label-content
        [formControl]="form().controls.newPassword"
        label="New Password"
      ></prime-label-content>
    </ng-template>

    <ng-template #inputContent>
      <prime-input-password
        [feedback]="false"
        [formControl]="form().controls.newPassword"
        [toggleMask]="true"
        inputStyleClass="w-full"
      ></prime-input-password>
    </ng-template>
  </prime-input-container>
  <prime-input-container labelClasses="mt-4">
    <ng-template #labelContent>
      <prime-label-content
        [formControl]="form().controls.confirmPassword"
        label="Confirm Password"
      ></prime-label-content>
    </ng-template>

    <ng-template #inputContent>
      <prime-input-password
        [feedback]="false"
        [formControl]="form().controls.confirmPassword"
        [toggleMask]="true"
        inputStyleClass="w-full"
      ></prime-input-password>
    </ng-template>
  </prime-input-container>
</div>

<ng-template
  #passwordRule
  let-rule
>
  <div class="flex align-items-center gap-2">
    <i
      [ngClass]="{
        'text-green-600': isRuleFollowed(rule.error)
      }"
      class="rule-color-icon flex w-1rem h-1rem"
      inlineSVG="sprites.svg#check"
    ></i>
    <div
      [ngClass]="isRuleFollowed(rule.error) ? 'text-green-600' : 'text-color-secondary'"
      class="text-color-secondary"
    >
      {{ rule.label }}
    </div>
  </div>
</ng-template>
