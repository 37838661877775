<div class="investor-portal-header-wrapper w-full h-full">
  <div class="h-full flex align-items-center">
    @if (selectedClient().logoFileUrl) {
      <img
        [src]="selectedClient().logoFileUrl"
        class="h-2_50rem ml-4 mr-2"
      />
    } @else {
      <i
        class="h-2_50rem w-2_50rem flex ml-4 mr-3"
        inlineSVG="sprites.svg#blankstate"
      ></i>
    }

    <div
      (click)="allowClientChange() && changeClient()"
      [ngClass]="{
        'cursor-pointer transition-all transition-duration-300 hover:bg-bluegray-200':
          allowClientChange()
      }"
      class="px-2 py-1 flex align-items-center gap-3 border-round relative"
    >
      <div class="text-4xl font-semibold line-height-2_50rem">
        {{ selectedClient().clientName }}
      </div>
      @if (allowClientChange()) {
        <i
          class="flex w-1rem h-1rem"
          inlineSVG="sprites.svg#arrows-up-down"
        ></i>
      }
      <div class="absolute bottom-0 left-0 ml-2 mt-4 flex gap-1 align-items-center">
        <div class="by-pinnakl text-color-secondary">BY</div>
        <img
          class="h-0_50rem"
          src="/assets/images/big-pinnakl-logo.png"
        />
      </div>
    </div>
  </div>
  <div class="flex align-items-center">
    <prime-router-toggle
      [config]="tabs"
      class=""
      styleClass=""
    >
      <ng-template
        #itemTemplate
        let-item
      >
        <a
          [routerLink]="item.routerLink"
          class="p-menuitem-link"
          pRipple
          routerLinkActive="p-menuitem-link-active"
        >
          <span class="p-menuitem-text">
            {{ item.label }}
          </span>
        </a>
      </ng-template>
    </prime-router-toggle>
  </div>
  <div class="flex align-items-center justify-content-end pr-4 relative">
    <div
      (click)="op.toggle($event)"
      class="relative cursor-pointer border-round-xl px-3 py-2 border-1 border-bluegray-200 flex align-items-center gap-2"
    >
      <i
        class="flex w-1rem h-1rem"
        inlineSVG="sprites.svg#three-hor-lines"
      ></i>
      <pinnakl-first-letter-avatar
        [nameString]="user?.firstName ?? user?.lastName ?? user?.email ?? ''"
        backgroundColor="#2A98FF"
        color="white"
        height="24px"
        width="24px"
      ></pinnakl-first-letter-avatar>
    </div>
    <p-overlayPanel
      #op
      appendTo="body"
      styleClass="p-overlay-arrow-right"
    >
      <div class="p-3 flex align-items-center gap-2">
        <pinnakl-first-letter-avatar
          [nameString]="user?.firstName ?? user?.lastName ?? user?.email ?? '-'"
          backgroundColor="#2A98FF"
          color="white"
          height="32px"
          width="32px"
        ></pinnakl-first-letter-avatar>
        <div class="flex flex-column">
          <div class="font-semibold line-height-1rem">
            {{ user?.firstName + ' ' + user?.lastName }}
          </div>
          <div class="font-size-13px line-height-1rem text-color-secondary">
            {{ user?.email }}
          </div>
        </div>
      </div>
      <p-divider styleClass="m-0" />
      <div class="flex flex-column align-items-center w-full gap-1 p-2">
        <a
          [routerLink]="['/settings']"
          class="w-full flex gap-2 align-items-center cursor-pointer w-full text-color border-round px-2 py-0_75rem no-underline hover:bg-bluegray-200"
        >
          <i
            class="flex w-1rem h-1rem"
            inlineSVG="sprites.svg#cog"
          ></i>
          <div>Settings</div>
        </a>
        <div
          (click)="logout()"
          class="w-full flex gap-2 align-items-center cursor-pointer w-full text-color border-round px-2 py-0_75rem no-underline hover:bg-bluegray-200"
        >
          <i
            class="rotate-180 flex w-1rem h-1rem"
            inlineSVG="sprites.svg#login"
          ></i>
          <div>Logout</div>
        </div>
      </div>
      @if (appVersion) {
        <p-divider styleClass="m-0" />
        <div class="my-2 py-2 ml-5 pl-0_75rem border-round text-color-secondary">
          {{ appVersion }}
        </div>
      }
    </p-overlayPanel>
  </div>
</div>

@if (logoutProcessing()) {
  <pinnakl-local-spinner-simple-mode-container
    [containerNgStyle]="{
      top: '0',
      left: '0'
    }"
    backgroundColor="rgba(255, 255, 255, 0.5)"
    containerHeight="100vh"
    containerWidth="100vw"
    position="absolute"
    size="150px"
  ></pinnakl-local-spinner-simple-mode-container>
}
