import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Inject, Injectable, inject } from '@angular/core';
import { CurrentUserStore } from '@pinnakl/core/data-providers';
import { HTTP_SERVICE_URL } from '@pinnakl/shared/constants';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  private readonly currentUserStore = inject(CurrentUserStore);
  url!: string;

  get origin(): string {
    if (this.url) {
      return new URL(this.url)?.origin ?? '';
    }
    return '';
  }

  get is3rdPartyRequest(): boolean {
    return ['https://api.ipdata.co'].includes(this.origin);
  }

  get isApiCoreService(): boolean {
    return this.origin.includes('apicore') || this.origin.includes('51490');
  }

  get isFileService(): boolean {
    return (
      this.origin.includes('backendmiscservice') ||
      this.origin.includes('externalservices') ||
      this.origin.includes('localhost:53591') ||
      this.origin.includes('external-services') ||
      this.origin.includes('documents')
    );
  }

  get isEmailsCollectorService(): boolean {
    return this.origin.includes('emailscollector') || this.origin.includes('localhost:5151');
  }

  constructor(
    @Inject(HTTP_SERVICE_URL) private readonly httpServiceUrl: string,
    private readonly authService: AuthService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // If request to google skip all checks
    if (request.url.includes('maps.googleapis.com') || request.url.includes('authorize')) {
      return next.handle(request);
    }

    if (
      request.method === 'GET' &&
      ['.json', '.svg', '.png', '.jpg'].some(end => request.url.endsWith(end))
    ) {
      return next.handle(request);
    }
    const userInSession = this.currentUserStore.currentUserInfo();
    const token = userInSession && userInSession['token'];
    this.url = request.url.includes('http') ? request.url : this.httpServiceUrl.concat(request.url);

    request = request.clone({
      url: this.url
    });
    // Omit modification of 3rd-party requests to prevent CORS errors
    if (!this.is3rdPartyRequest) {
      if (token) {
        if (!request.headers.has('authorization')) {
          const sendToken = this.formatToken(token);
          request = this.addHeader(request, 'Authorization', sendToken);
        }
      }
      if (!request.headers.has('Content-Type') && !this.isFileService) {
        request = this.addHeader(request, 'Content-Type', 'application/json');
      }
      request = this.addHeader(request, 'Accept', 'application/json');
    }

    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && (error.status === 401 || error.status === 403)) {
          const authWellKnownEndPoints = this.authService.authWellKnownEndPoints;
          const { pathname, origin } = new URL(error.url ?? '');
          const isAuthUrl = Object.values(authWellKnownEndPoints).includes(origin + pathname);
          if (!this.is3rdPartyRequest && !isAuthUrl) {
            console.error('Unauthorized', error.message, error.status);
            return this.handle401Error(request, next);
          }
          return this.handleError(error);
        }
        return this.handleError(error);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    return this.authService.refreshingAuthState.asObservable().pipe(
      filter(r => !r),
      take(1),
      switchMap(() => this.authService.getAccessToken()),
      switchMap(accessToken =>
        next.handle(this.addHeader(request, 'Authorization', this.formatToken(accessToken)))
      ),
      catchError(err => {
        this.authService.localLogout();
        return this.handleError(err);
      })
    );
  }

  private addHeader(request: HttpRequest<any>, headerKey: string, headerValue: string) {
    return request.clone({ headers: request.headers.set(headerKey, headerValue) });
  }

  private formatToken(token: string): string {
    return this.isApiCoreService || this.isFileService || this.isEmailsCollectorService
      ? 'Bearer ' + token
      : token;
  }

  private handleError(err: HttpErrorResponse): Observable<any> {
    console.error(err.message);
    return throwError(() => err);
  }
}
